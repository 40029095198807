import React from 'react'
import Navbar from '../../../Navbar'
import Footer from '../../../Footer'
import ScrollTop from '../../../ScrollTop'

const Semiprofesional3 = () => {
  return (
    <ScrollTop>
      <Navbar/>
      <Footer/>
      
    </ScrollTop>
  )
}

export default Semiprofesional3